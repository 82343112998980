@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@100;200;300;400;500;600&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer components{

  .navbar-white{
    @apply flex items-center justify-between px-8 fixed w-screen h-16 sm:h-20 top-0 shadow-sm bg-white z-30
  }

  .navbar-black{
    @apply flex items-center justify-between px-8 fixed w-screen h-16 top-0 shadow-sm bg-black 
    z-30 
  }

  .unhidden{
    @apply bg-black text-white w-screen z-20 h-screen fixed top-16 overflow-hidden font-primary text-2xl px-4 pt-12 sm:hidden
  }

  .desktop-nav{
    @apply flex gap-12 font-ibm text-2xl mx-8 
  }

}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* for IE and Edge */
  scrollbar-width: none;  /* for Firefox */
}

.grid1{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  background-color: black;
  grid-gap: 1px;
}

.boxglow{
  box-shadow: 0px 31px 55px rgba(255, 255, 255, 0.21);
}